import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useStyles } from './styles';
import LazyLoad from 'react-lazyload';
import Image from 'next/image';

export default function TrustedResearchersMobile() {
  const classes = useStyles();

  return (
    <LazyLoad offset={100}>
      <Box pt={9} pb={1} bgcolor="common.white">
        <Container>
          <Typography className={classes.mainTitle} component="h2" align="center">
            <b>
              <span>Trusted by</span> researchers and academics from 100+ institution
            </b>
          </Typography>

          <Box className={classes.logoWrapper} pt={4}>
            <Image
              src="/images/stanford-logo.webp"
              height={36}
              width={82}
              loading="lazy"
              alt="Stanford logo"
            />

            <Image
              src="/images/pearson-logo.webp"
              height={54}
              width={74}
              loading="lazy"
              alt="Pearson logo"
            />

            <Image
              src="/images/ucla-logo.webp"
              height={22}
              width={68}
              loading="lazy"
              alt="UCLA logo"
            />
          </Box>

          <Box className={classes.logoWrapper} pt={2} pb={4}>
            <Image
              src="/images/harvard-logo.webp"
              height={32}
              width={124}
              loading="lazy"
              alt="Harvard logo"
            />

            <Image
              src="/images/abbott-logo.webp"
              height={26}
              width={94}
              loading="lazy"
              alt="Abbott logo"
            />

            <Image
              src="/images/mcgill-logo.webp"
              height={24}
              width={106}
              loading="lazy"
              alt="Mcgill logo"
            />
          </Box>
        </Container>
      </Box>
    </LazyLoad>
  );
}
