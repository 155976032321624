import { Box, Container, Typography } from '@mui/material';
import dynamic from 'next/dynamic';
import { useStyles } from './styles';
import { LandingPageContextEnum } from 'enums';
import { COLOR_PURPLE } from 'styles/variables/colors';
import WrapperBackgroundGraphics from 'components/WrapperBackgroundGraphics';
import VideoSwitcher from 'components/VideoSwitcher';

const DynamicEmailFormVariant = dynamic(() => import('components/EmailFormVariant'));

export default function Header() {
  const classes = useStyles();

  return (
    <WrapperBackgroundGraphics>
      <Container>
        <Box className={classes.content}>
          <Box>
            <Typography component="h1" variant="h4">
              <b>
                Science figures,
                <br /> graphical abstracts,
                <br /> and infographics for <br /> your research.
                <br /> <span>For Free.</span>
              </b>
            </Typography>

            <Box pt={3} className={classes.subheadline}>
              <Typography>
                Browse through <strong>75.000+</strong> scientifically accurate <br /> illustrations
                in <strong>80+</strong> popular fields
              </Typography>
            </Box>

            <DynamicEmailFormVariant
              buttonColor={COLOR_PURPLE}
              textButtonLogged="Start Creating Now"
              context={LandingPageContextEnum.HOME_PAGE}
            />

            <Box display="flex" justifyContent="center" mt={2}>
              <Box mr={0.8}>
                <Typography variant="body1" component="p" style={{ fontSize: '18px' }}>
                  No credit card required
                </Typography>
              </Box>
            </Box>
          </Box>
          <VideoSwitcher />
        </Box>
      </Container>
    </WrapperBackgroundGraphics>
  );
}
