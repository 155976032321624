import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import LazyLoad from 'react-lazyload';
import { useRouter } from 'next/router';
import CustomButton from 'components/CustomButton';
import { makeStyles } from '@mui/styles';
import { useAuthUser } from 'features/auth/AuthUserContext';

const useStyles = makeStyles(() => ({
  mainTitle: {
    color: '#7833FF',
    lineHeight: 1.2,
  },
  textInfo: {
    '& a': {
      textDecoration: 'underline',
    },
  },
  videoWrapper: {
    zIndex: 1,
  },
}));

const DefaultProps = {
  boxBgColor: '#FFFFFF',
  textButtonLogged: 'SIGN UP FOR FREE',
  textButtonNotLogged: 'SIGN UP FOR FREE',
};

export default function AccurateIllustrationsGalleryMobile({
  boxBgColor,
  textButtonLogged,
  textButtonNotLogged,
  buttonClickProp,
}) {
  const classes = useStyles();
  const router = useRouter();
  const { isAuthenticated } = useAuthUser();

  function handleButtonClick() {
    router.push('/unsupported-device');
  }

  return (
    <Box pt={4} bgcolor={boxBgColor}>
      <Container>
        <LazyLoad offset={100}>
          <Typography component="h2" variant="h6" align="center" className={classes.mainTitle}>
            <b>The World's Largest Scientifically-Accurate Illustrations Gallery</b>
          </Typography>

          <Box py={2}>
            <Typography component="p" variant="body2" className={classes.textInfo} align="center">
              Over 40,000 accurate scientific figures to <br /> boost your impact.
            </Typography>
          </Box>

          <Box textAlign="center" mt={2}>
            <video
              src="/videos/accurate-illustrations-gallery/versions-new-workspace-video.webm"
              alt="versions video"
              loading="lazy"
              muted={true}
              loop={true}
              autoPlay={true}
              width={250}
            />
          </Box>

          <Box py={5} textAlign="center">
            <CustomButton onClick={buttonClickProp || handleButtonClick}>
              {!isAuthenticated ? textButtonNotLogged : textButtonLogged}
            </CustomButton>
          </Box>
        </LazyLoad>
      </Container>
    </Box>
  );
}

AccurateIllustrationsGalleryMobile.defaultProps = DefaultProps;
