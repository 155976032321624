import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_GRAY_90, COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  memorable: {
    '& h2': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 24,
      '& b': {
        fontWeight: 700,
        color: COLOR_PURPLE,
      },
    },
    '& p': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 16,
      lineHeight: '22px',
      fontStyle: 'italic',
    },
    '& button': {
      width: '100%',
    },
  },
  boxMohammed: {
    '& a': {
      fontSize: 18,
      fontWeight: 'bold',
      display: 'block',
      marginBottom: theme.spacing(1),
      color: COLOR_GRAY_90,
    },

    '& p': {
      fontStyle: 'inherit',
    },
  },
}));
