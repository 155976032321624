import React from 'react';
import NextLink from 'next/link';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Link, Typography } from '@mui/material';
import { useStyles } from './styles';
import LazyLoad from 'react-lazyload';

const DynamicCircularCarousel = dynamic(() => import('components/CircularCarousel'));

const testimonials = [
  {
    name: 'Margarida Agrochão',
    info: 'PhD student at Caltech / USA',
    text: 'No other tool does what Mind the Graph does!',
    image: '/images/testimonials/picture_margarida.png',
    socialLink: 'https://www.linkedin.com/in/margarida-agrochao-phd-52176116/',
  },
  {
    name: 'Meredith Thompson',
    info: 'MIT Researcher / USA',
    text: 'Nice tools to conceptualize the graphical abstract for a journal article!',
    image: '/images/testimonials/picture_meredith.png',
    socialLink: 'https://www.linkedin.com/in/meredith-thompson-5abb9837',
  },
  {
    name: 'Susanne Fiedler',
    info: 'PhD Student at LMU / Germany',
    text: 'Very easy to use and graphs are designed quite quickly!',
    image: '/images/testimonials/picture_susanne.png',
    socialLink: 'https://www.facebook.com/zusanne.fiedler.7/',
  },
  {
    name: 'Shermel Sherman',
    info: 'Predoctoral Research Assistant at UT / USA',
    text: 'The nest building illustrations are perfect. Thank you!',
    image: '/images/testimonials/shermel.png',
    socialLink: 'https://www.researchgate.net/profile/Shermel_Sherman',
  },
  {
    name: 'Oscar García Miranda',
    info: 'Student at UDLAP  / Mexico',
    text: 'Thank you so much! They are awesome!',
    image: '/images/testimonials/oscar.png',
    socialLink: 'https://www.researchgate.net/profile/Oscar_Garcia-Miranda',
  },
  {
    name: 'Mahmoud Slim',
    info: 'Phd at Ave / Canada',
    text: 'Thank you so much for your assistance!',
    image: '/images/testimonials/mahmoud.png',
    socialLink: 'https://www.researchgate.net/profile/Mahmoud_Slim',
  },
  {
    name: 'Benjamin Zeitlin',
    info: 'Teacher at UOP / USA',
    text: 'Thank you, this was exactly what I needed!',
    image: '/images/testimonials/benjamin.png',
    socialLink: 'https://www.researchgate.net/profile/Benjamin_Zeitlin',
  },
  {
    name: 'Lara Moreno Zarate',
    info: 'PhD student at UCLM / Spain',
    text: 'Congrats for this platform and the work you are offering! it is great',
    image: '/images/testimonials/lara.png',
    socialLink: 'https://www.researchgate.net/profile/Lara_Moreno-Zarate',
  },
  {
    name: 'Selen Uman',
    info: 'PhD Student at UPENN / USA',
    text: 'The figures look great! thank you so much!',
    image: '/images/testimonials/selen.png',
    socialLink: 'https://www.gatescambridge.org/members-area/connect/directory/scholar/7252',
  },
  {
    name: 'Mohammed K Hankir',
    info: 'Basic Scientist at JMU / Germany',
    text: 'From day 1 the platform helped me to produce professional-looking slides for presentations and figures for publications.',
    image: '/images/testimonials/mohammed.png',
    socialLink: 'https://www.researchgate.net/profile/Mohammed_Hankir2',
  },
  {
    name: 'Emily Landon',
    info: 'Executive Medical Director at UC / USA',
    text: 'You guys are awesome!',
    image: '/images/testimonials/emily.png',
    socialLink: 'https://www.researchgate.net/profile/Emily_Landon2',
  },
  {
    name: 'Gabriela Torres Mattos',
    info: 'PhD student at UFRGS / Brazil',
    text: 'I bought the wrong plan and the support team was very attentive and solved my situation',
    image: '/images/testimonials/gabriela.png',
    socialLink: 'http://lattes.cnpq.br/6037855682278478',
  },
  {
    name: 'Benedikt Heuckmann',
    info: 'PostDoc at Leibniz Universität Hannover / Germany',
    text: 'Your support is very good and the illustrations are scientifically exact. Thank you so much for your help. This service is absolutely awesome',
    image: '/images/testimonials/benedikt.png',
    socialLink: 'https://www.researchgate.net/profile/Benedikt_Heuckmann',
  },
  {
    name: 'Sonali Raghunath',
    info: 'PhD Student at UMN / USA',
    text: 'It was easier to design exactly how I wanted to present the graphic in my scientific paper',
    image: '/images/testimonials/sonali.png',
    socialLink: 'https://www.researchgate.net/profile/Sonali_Raghunath2',
  },
  {
    name: 'Amro Hussien',
    info: 'PhD Student at ETH Zürich / Switzerland',
    text: 'Easy to use - Clean graphics - Big illustration library',
    image: '/images/testimonials/amro.png',
    socialLink: 'https://biomech.ethz.ch/the-institute/people/person-detail.html?persid=208489',
  },
  {
    name: 'Kaian Teles',
    info: 'PhD student at UnB / Brazil',
    text: 'I really like mind the graph because they have beautiful and useful templates',
    image: '/images/testimonials/kaian.png',
    socialLink: 'https://www.researchgate.net/profile/Kaian_Teles',
  },
];

const Props = {
  showActionButton: PropTypes.bool,
  actionButton: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }),
};

const DefaultProps = {
  showActionButton: true,
  actionButton: {
    href: '/feedbacks',
    label: 'See all testimonials',
    disabled: false,
    onClick: () => {},
  },
};

export default function TestimonialsMobile({
  showActionButton,
  actionButton,
  testimonialsTranslated,
}) {
  const classes = useStyles();

  return (
    <LazyLoad offset={100}>
      <Box mt={-7} mb={2}>
        <Typography className={classes.primaryColor} component="h2" variant="h6" align="center">
          <b>
            See what people are talking about <br /> Mind The Graph!
          </b>
        </Typography>
      </Box>

      <DynamicCircularCarousel
        classes={{
          root: classes.carouselRoot,
          carousel: classes.carousel,
          carouselItem: classes.carouselItem,
        }}
        autoplay={true}
        controlButtons={false}
        items={(testimonialsTranslated || testimonials).map((testimonial) => (
          <div className={classes.item} key={testimonial.name}>
            <Link
              className={classes.avatarLink}
              href={testimonial.socialLink}
              target="_blank"
              underline="hover"
            >
              <img
                src={testimonial.image}
                loading="lazy"
                height="60"
                width="60"
                alt="User avatar"
              />
            </Link>

            <Typography variant="body2">"{testimonial.text}"</Typography>

            <Divider className={classes.divider} />

            <Typography className={classes.testimonialName} variant="h3">
              <Link href={testimonial.socialLink} target="_blank" color="inherit" underline="hover">
                <b>{testimonial.name}</b>
              </Link>
            </Typography>

            <Typography className={classes.testimonialInfo}>{testimonial.info}</Typography>

            <img
              className={classes.ratingIcon}
              src="/images/five-stars.png"
              loading="lazy"
              height="23"
              width="98"
              alt="User feedback icon"
            />
          </div>
        ))}
      />

      {showActionButton && (
        <Box mt={4} textAlign="center">
          <NextLink href={actionButton.href} passHref={true}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={actionButton.disabled}
              onClick={actionButton.onClick}
            >
              {actionButton.label}
            </Button>
          </NextLink>
        </Box>
      )}
    </LazyLoad>
  );
}

TestimonialsMobile.propTypes = Props;
TestimonialsMobile.defaultProps = DefaultProps;
