import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_PINK } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  graphicalAbstracts: {
    '& p': {
      marginBottom: theme.spacing(3),
      color: COLOR_GRAY_70,
      fontSize: 16,
      lineHeight: '19px',
      fontFamily: 'Lato',
    },
    '& button': {
      width: '100%',
    },
  },
  graphicalAbstractsTitle: {
    fontFamily: 'Lato',
    fontSize: 24,
    lineHeight: '28px',
    color: COLOR_GRAY_70,
    marginBottom: theme.spacing(4),
    '& b': {
      color: COLOR_PINK,
    },
  },
}));
