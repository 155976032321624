import React from 'react';
import { Grid, Box, Typography, Container } from '@mui/material';
import { useStyles } from './styles';
import LazyLoad from 'react-lazyload';
import { useRouter } from 'next/router';
import CustomButton from 'components/CustomButton';
import { useAuthUser } from 'features/auth/AuthUserContext';

const DefaultProps = {
  title: <b>Your Creations, Ready within Minutes!</b>,
  variantTitle: 'h4',
  showButton: false,
  textButtonLogged: 'SIGN UP FOR FREE',
  textButtonNotLogged: 'SIGN UP FOR FREE',
};

export default function InfodesignMobile({
  title,
  variantTitle,
  showButton,
  textButtonLogged,
  textButtonNotLogged,
  buttonClickProp,
}) {
  const classes = useStyles();
  const router = useRouter();
  const { isAuthenticated } = useAuthUser();

  function handleButtonClick() {
    if (!isAuthenticated) {
      router.push(`/auth/login-register?from_url=${router.basePath + router.asPath}`);
    } else {
      router.push('/unsupported-device');
    }
  }

  return (
    <Box px={2} py={4} bgcolor="#FAFAFA">
      <LazyLoad offset={100}>
        <Grid item xs={12}>
          <Typography
            variant={variantTitle}
            component="h2"
            align="center"
            className={classes.title}
          >
            {title}
          </Typography>
        </Grid>
      </LazyLoad>

      <Container>
        <LazyLoad offset={100}>
          <Grid className={classes.itemContainer} container={true} spacing={2} alignItems="center">
            <Grid item={true} xs={12}>
              <div className={classes.item}>
                <img
                  src="/images/premade-icon.webp"
                  loading="lazy"
                  height="75"
                  width="76.66"
                  alt="Pre-made resources"
                />

                <Typography component="h2">Pre-Made Resources</Typography>
                <Typography className={classes.subTextContent}>
                  Don't waste time! <br /> Use our templates
                </Typography>
              </div>
            </Grid>

            <Grid item={true} xs={12}>
              <div className={classes.item}>
                <img
                  src="/images/ondemand-icon.webp"
                  loading="lazy"
                  height="75"
                  width="61.42"
                  alt="On-demand illustrations"
                />

                <Typography component="h2">On-demand Illustrations</Typography>
                <Typography className={classes.subTextContent}>
                  Don't find exactly what you need? <br /> We will design it for you!
                </Typography>
              </div>
            </Grid>

            <Grid item={true} xs={12}>
              <div className={classes.item}>
                <img
                  src="/images/multiple-sizing-options-icon.webp"
                  loading="lazy"
                  height="75"
                  width="73.27"
                  alt="Pre-set sizes"
                />

                <Typography component="h2">Multiple Sizing Options</Typography>
                <Typography className={classes.subTextContent}>
                  Ready-to-go templates <br /> in all popular sizes
                </Typography>
              </div>
            </Grid>

            <Grid item={true} xs={12}>
              <div className={classes.item}>
                <img
                  src="/images/online-platform-icon.webp"
                  loading="lazy"
                  height="75"
                  width="76.75"
                  alt="Online plataform"
                />

                <Typography component="h2">Online Platform</Typography>
                <Typography className={classes.subTextContent}>
                  Access your creations <br /> at any time and from anywhere
                </Typography>
              </div>
            </Grid>

            <Grid item={true} xs={12}>
              <div className={classes.item}>
                <img
                  src="/images/drag-drop-icon.webp"
                  loading="lazy"
                  height="75"
                  width="69.16"
                  alt="Drag and Drop"
                />

                <Typography component="h2">Drag and Drop</Typography>
                <Typography className={classes.subTextContent}>
                  Easily select and manage <br /> illustrations, text and templates
                </Typography>
              </div>
            </Grid>

            <Grid item={true} xs={12}>
              <div className={classes.item}>
                <img
                  src="/images/slides-icon.webp"
                  loading="lazy"
                  height="75"
                  width="75"
                  alt="Slides presentation"
                />

                <Typography component="h2">Slides Presentations</Typography>
                <Typography className={classes.subTextContent}>
                  Integrate information and <br /> illustrations into beautiful <br /> and impactful
                  slides
                </Typography>
              </div>
            </Grid>
          </Grid>

          {showButton && (
            <Box textAlign="center">
              <CustomButton onClick={buttonClickProp || handleButtonClick}>
                {!isAuthenticated ? textButtonNotLogged : textButtonLogged}
              </CustomButton>
            </Box>
          )}
        </LazyLoad>
      </Container>
    </Box>
  );
}

InfodesignMobile.defaultProps = DefaultProps;
