import React from 'react';
import dynamic from 'next/dynamic';
import useIsMobile from 'hooks/useIsMobile';
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import AccurateIllustrationsGallery from 'components/AccurateIllustrationsGallery';
import UnleashInfographics from 'components/UnleashInfographics';
import LazyLoad from 'react-lazyload';
import FriendsReferralBanner from 'components/FriendsReferralBanner';
import PreMadeTemplates from 'components/PreMadeTemplates';
import ScientificDesignTool from 'components/ScientificDesignTool';
import CustomizableGraphicalAbstracts from 'components/CustomizableGraphicalAbstracts';
import UnderstandableInfographics from 'components/UnderstandableInfographics';
import ImpactfulPosters from 'components/ImpactfulPosters';
import MemorableSlidePresentations from 'components/MemorableSlidePresentations';
import { useModal } from '../../../contexts/EmailFormModalContext';

const DynamicTestimonials = dynamic(() => import('components/Testimonials'));
const DynamicInfodesign = dynamic(() => import('components/Infodesign'));
const DynamicFaq = dynamic(() => import('components/Home/Faq'));
const DynamicTrustedByResearchers = dynamic(() => import('components/TrustedByResearchers'));

const useStyles = makeStyles((theme) => ({
  infoText: {
    color: '#757575',
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  button: {
    textTransform: 'none',
    border: '1px solid #FFFFFF',
    borderRadius: 30,
    padding: theme.spacing(1.5, 3),
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#7833FF',
    },
  },
}));

export default function MainContent() {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { setIsModalOpen } = useModal();

  return (
    <>
      <Box mt={3}>
        <DynamicTrustedByResearchers
          title={
            <>
              <span>Trusted by</span> researchers and academics from 100+ institutions
            </>
          }
          variantTitle={'h5'}
        />
      </Box>

      <PreMadeTemplates />

      <ScientificDesignTool />

      <CustomizableGraphicalAbstracts />

      <UnderstandableInfographics />

      <ImpactfulPosters />

      <MemorableSlidePresentations />

      <AccurateIllustrationsGallery ctaButton={true} />

      <DynamicInfodesign
        variantTitle="h5"
        variantInfoText="body2"
        classes={{ infoText: classes.infoText }}
        showButton={true}
        ctaButton={true}
      />

      <UnleashInfographics />

      <Container>
        <Box bgcolor="#FAFAFA" py={4}>
          <LazyLoad offset={100}>
            <DynamicFaq />
          </LazyLoad>
        </Box>
      </Container>

      {!isMobile && <FriendsReferralBanner />}

      <Box py={5} bgcolor="#5F29CC">
        <Container>
          <LazyLoad offset={100}>
            <Typography className={classes.whiteColor} align="center" component="h2" variant="h4">
              <b>
                Ready to start creating infographics <br /> and scientific illustrations?
              </b>
            </Typography>

            <Box py={2}>
              <Typography className={classes.whiteColor} align="center">
                Make scientifically accurate <br /> infographics in minutes
              </Typography>
            </Box>

            <Box pt={2} align="center">
              <Button className={classes.button} onClick={setIsModalOpen}>
                <b>Get started</b>
              </Button>
            </Box>
          </LazyLoad>
        </Container>
      </Box>

      <Box py={10} bgcolor="#FFFFFF">
        <Container>
          <LazyLoad offset={100}>
            <DynamicTestimonials />
          </LazyLoad>
        </Container>
      </Box>
    </>
  );
}
