import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const Props = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    wrapper: PropTypes.string,
    iframe: PropTypes.string,
  }),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  url: PropTypes.string.isRequired,
};

const DefaultProps = {
  classes: {},
};

export default function EmbedVideo({ classes: customClasses, width, url }) {
  const classes = useStyles({ width });
  const content = (
    <div className={clsx(classes.wrapper, customClasses.wrapper)}>
      <iframe
        className={clsx(classes.iframe, customClasses.iframe)}
        src={url}
        title="Video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      />
    </div>
  );

  if (width) {
    return <div className={clsx(classes.root, customClasses.root)}>{content}</div>;
  }

  return content;
}

EmbedVideo.propTypes = Props;
EmbedVideo.defaultProps = DefaultProps;
