import { Box, Container, Typography } from '@mui/material';
import Image from 'next/image';
import { useStyles } from './styles';
import CustomButton from 'components/CustomButton';
import { useRouter } from 'next/router';
import LazyLoad from 'react-lazyload';

export default function ScientificDesignToolMobile() {
  const classes = useStyles();
  const router = useRouter();

  function handleButtonClick() {
    router.push(`/auth/login-register?from_url=${router.basePath + router.asPath}`);
  }

  return (
    <Box px={1} className={classes.onlineScientific} py={7} bgcolor="#EFEDF6">
      <Container>
        <LazyLoad offset={100}>
          <Typography component="h2">
            <b>Online scientific design</b> tool for your classes, speeches, papers and much more!
          </Typography>

          <Typography>
            <strong>Craft your own personalized narratives,</strong> with a suite of editing tools
            including color alteration, cropping, text addition, and more!
          </Typography>

          <CustomButton variant="cta" onClick={handleButtonClick}>
            Sign up for free
          </CustomButton>

          <Box
            display="flex"
            flexDirection="column"
            py={1.25}
            gap="10px"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              src="/images/home-page-mobile/peoples.webp"
              width={114}
              height={32.9}
              alt="infographic image"
              loading="lazy"
            />

            <Typography className="description">
              <span>
                <b>500,000+ users are skyrocketing their academic careers with us!</b>
              </span>
            </Typography>
          </Box>
        </LazyLoad>
      </Container>
    </Box>
  );
}
