import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useStyles } from './styles';
import Video from 'components/Video';
import Image from 'next/image';
import { Fade } from '@mui/material';

export default function VideoSwitcher() {
  const classes = useStyles();
  const [stepVideo, setStepVideo] = useState(1);
  const [fade, setFade] = useState(true);
  const [additionalVideosLoaded, setAdditionalVideosLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        setAdditionalVideosLoaded(true);
      }, 1300);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  function video(step) {
    setFade(false);
    setTimeout(() => {
      setStepVideo(step);
      setFade(true);
    }, 300);
  }

  function videoSources() {
    return {
      1: '/videos/videos-switcher/medical-science-video.webm',
      2: '/videos/videos-switcher/biology-video.webm',
      3: '/videos/videos-switcher/microbiology-video.webm',
      4: '/videos/videos-switcher/plus-fields-video.webm',
    };
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Box className={classes.mainBoxStepVideos}>
        <Button className={stepVideo === 1 ? classes.activeButton : ''} onClick={() => video(1)}>
          Medical Science
        </Button>

        <Button className={stepVideo === 2 ? classes.activeButton : ''} onClick={() => video(2)}>
          Biology
        </Button>

        <Button className={stepVideo === 3 ? classes.activeButton : ''} onClick={() => video(3)}>
          Microbiology
        </Button>

        <Button className={stepVideo === 4 ? classes.activeButton : ''} onClick={() => video(4)}>
          80+ fields of study
        </Button>
      </Box>
      <Box className={classes.videoWrapper} mb={0} textAlign="center">
        {additionalVideosLoaded ? (
          <Fade in={fade} timeout={300}>
            <div>
              <Video
                className={classes.video}
                sources={[{ src: videoSources()[stepVideo], type: 'video/webm' }]}
                autoPlay
                preload="metadata"
              />
            </div>
          </Fade>
        ) : (
          <div className={classes.image}>
            <Image
              src="/videos/videos-switcher/placeholder.webp"
              width={650}
              height={430}
              alt="Placeholder image"
              priority={true}
            />
          </div>
        )}

        {additionalVideosLoaded && (
          <>
            {Object.keys(videoSources()).map(
              (step) =>
                step != stepVideo && (
                  <Video
                    key={videoSources()[step]}
                    className={classes.video}
                    sources={[{ src: videoSources()[step], type: 'video/webm' }]}
                    preload="metadata"
                    style={{ display: 'none' }}
                  />
                ),
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
