import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Video from 'components/Video';
import { useStyles } from './styles';
import LazyLoad from 'react-lazyload';
import { COLOR_PURPLE, COLOR_WHITE } from 'styles/variables/colors';
import EmailFormVariant from 'components/EmailFormVariant';
import { LandingPageContextEnum } from 'enums';
import WrapperBackgroundGraphicsMobile from 'components/WrapperBackgroundGraphicsMobile';

export default function HeaderMobile() {
  const classes = useStyles();

  return (
    <WrapperBackgroundGraphicsMobile>
      <Box className={classes.root} px={1} bgcolor={COLOR_WHITE} mb={20}>
        <Container>
          <Box className={classes.contentTitle}>
            <Box>
              <Typography component="h1" variant="h5">
                <b>
                  Science figures, graphical abstracts, and infographics for your research. <br />
                  <span>For Free.</span>
                </b>
              </Typography>
            </Box>

            <Box py={3}>
              <Typography style={{ position: 'relative', zIndex: 1 }}>
                Browse through <strong>75.000+</strong> scientifically accurate illustrations in{' '}
                <strong>80+</strong> popular fields.
              </Typography>
            </Box>

            <Box className={classes.ctaHero}>
              <EmailFormVariant
                buttonColor={COLOR_PURPLE}
                textButtonLogged="Start Creating Now"
                textButtonNotLogged="Start Creating Now"
                context={LandingPageContextEnum.HOME_PAGE}
              />

              <Typography>No credit card required</Typography>
            </Box>

            <Box className={classes.videoWrapper} mb={-25}>
              <LazyLoad offset={50}>
                <Video
                  className={classes.video}
                  sources={[
                    { src: '/videos/new-header-banner-new-workspace.webm', type: 'video/webm' },
                  ]}
                />
              </LazyLoad>
            </Box>
          </Box>
        </Container>
      </Box>
    </WrapperBackgroundGraphicsMobile>
  );
}
