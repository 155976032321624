import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0 0 12px 0',
    color: '#7833FF',
  },
  itemContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  item: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),

    '& img': {
      height: 75,
    },

    '& h2': {
      color: '#7833FF',
      fontSize: 17,
      fontWeight: 'bold',
      marginTop: 17,
    },
  },
  subTextContent: {
    fontSize: 14,
    marginTop: 3,
  },
}));
