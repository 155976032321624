import { Box, Container, Typography } from '@mui/material';
import Image from 'next/image';
import { useStyles } from './styles';
import CustomButton from 'components/CustomButton';
import { useRouter } from 'next/router';
import LazyLoad from 'react-lazyload';

export default function UnderstandableInfographicsMobile() {
  const classes = useStyles();
  const router = useRouter();

  function handleButtonClick() {
    router.push(`/auth/login-register?from_url=${router.basePath + router.asPath}`);
  }

  return (
    <Box px={1} py={7} className={classes.understandable} bgcolor="#F8F8F8">
      <Container>
        <LazyLoad offset={100}>
          <Box mb={4} alignItems="center" display="flex" justifyContent="center">
            <Image
              src="/images/home-page-mobile/understandable.webp"
              width={273}
              height={230}
              alt="infographic image"
              loading="lazy"
            />
          </Box>

          <Typography component="h2" variant="h6">
            Easily Understandable <b>Infographics</b>
          </Typography>

          <Box my={3}>
            <Typography>
              Research indicates a <strong>120% growth in citations</strong> for articles using
              infographics.
            </Typography>

            <br />

            <Typography>Why?</Typography>

            <br />

            <Typography>
              Because they are a great tool for distilling complex data and content{' '}
              <strong>into digestible, engaging visuals.</strong>
            </Typography>
          </Box>

          <CustomButton variant="ctaPrimaryOutline" onClick={handleButtonClick}>
            Sign up for free
          </CustomButton>
        </LazyLoad>
      </Container>
    </Box>
  );
}
