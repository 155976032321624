import { makeStyles } from '@mui/styles';
import { COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  templates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row-reverse',

    '& p': {
      color: '#616161',
    },

    '& h2': {
      fontSize: 30,
      fontWeight: 400,
      color: '#616161',
      margin: 0,
    },

    '& h2 span': {
      color: COLOR_PURPLE,
      fontWeight: 700,
    },
  },
  paragraph: {
    lineHeight: 1.15,
    fontFamily: 'Lato',
  },
}));
