import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Props = {
  id: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const DefaultProps = {
  id: 'video',
  loop: true,
  muted: true,
};

export default function Video({ id, sources, ...rest }) {
  useEffect(() => {
    function play() {
      const videoElement = document.getElementById(id);

      videoElement.load();

      videoElement.addEventListener('loadeddata', () => {
        if (videoElement.readyState === 4) {
          videoElement.play();
        }
      });
    }

    play();
  }, []);

  return (
    <video id={id} {...rest}>
      {sources.map((source, index) => (
        <source key={index} src={source.src} type={source.type} />
      ))}
      Your browser does not support HTML video.
    </video>
  );
}

Video.propTypes = Props;
Video.defaultProps = DefaultProps;
