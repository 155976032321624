import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  videoWrapper: {
    zIndex: 1,
    minHeight: 433,
  },
  video: {
    width: '90%',
    borderRadius: 8,

    [theme.breakpoints.up('md')]: {
      width: 600,
      marginTop: 0,
      zIndex: 2,
    },
  },
  mainBoxStepVideos: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#F9FAFB',
    zIndex: 99,
    borderRadius: 8,
    marginBottom: theme.spacing(2),
    border: '1px solid #EAECF0',
    padding: theme.spacing(0.75),
    transition: 'all 0.3s ease',
    width: '100%',

    '& > button': {
      fontWeight: 'bold',
      minWidth: 'auto',
      flexGrow: 0,
      whiteSpace: 'nowrap',
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 6,
      padding: theme.spacing(1.25, 1.75),
      cursor: 'pointer',
      textTransform: 'initial',

      '&:hover': {
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        background: '#fff',
      },
    },
  },
  activeButton: {
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    backgroundColor: '#FFF',
    color: COLOR_PURPLE + ' !important',
  },
  image: {
    '& > span': {
      borderRadius: 12,
    },
  },
}));
