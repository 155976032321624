import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  primaryColor: {
    color: '#7833FF',
  },
  stepNumber: {
    backgroundColor: '#7833FF',
    padding: theme.spacing(1),
    fontSize: 24,
    color: theme.palette.common.white,
    borderRadius: 4,
  },
  chevron: {
    fontSize: theme.typography.h3.fontSize,
    color: '#7833FF',
    marginRight: theme.spacing(2),
  },
}));
