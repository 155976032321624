import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_PINK, COLOR_PURPLE, COLOR_WHITE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  whiteColor: {
    color: theme.palette.common.white,
  },
  templatesTitle: {
    fontFamily: 'Lato',
    fontSize: 24,
    lineHeight: '28px',
    color: COLOR_GRAY_70,
    '& b': {
      color: COLOR_PINK,
    },
    marginBottom: theme.spacing(3),
  },
  descriptionTemplates: {
    marginBottom: theme.spacing(3),
    color: COLOR_GRAY_70,
    fontSize: 16,
    lineHeight: '19px',
  },
  templatesContent: {
    '& button': {
      fontFamily: 'Lato',
      transition: '.3s',
      color: COLOR_PINK,
      height: '52px',
      width: '100%',
      borderRadius: '1000px',
      border: `2px solid ${COLOR_PINK}`,
      background: 'transparent',
      fontWeight: 'bold',

      '&:hover': {
        background: COLOR_PINK,
        color: COLOR_WHITE,
      },
    },
  },

  onlineScientific: {
    '& h2': {
      fontFamily: 'Lato',
      lineHeight: '28.8px',
      fontSize: 24,
      color: COLOR_GRAY_70,
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      '& b': {
        color: COLOR_PURPLE,
      },
    },
    '& p': {
      color: COLOR_GRAY_70,
      fontSize: 16,
      lineHeight: '22px',
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      fontFamily: 'Lato',
    },

    '& button': {
      width: '100%',
      marginBottom: theme.spacing(2),
      fontSize: 18,
    },
    '& span b': {
      display: 'block',
      fontSize: 14,
      textAlign: 'center',
    },
  },
  graphicalAbstracts: {
    '& p': {
      marginBottom: theme.spacing(3),
      color: COLOR_GRAY_70,
      fontSize: 16,
      lineHeight: '19px',
      fontFamily: 'Lato',
    },
    '& button': {
      fontFamily: 'Lato',
      transition: '.3s',
      color: COLOR_PINK,
      height: '52px',
      width: '100%',
      borderRadius: '1000px',
      border: `2px solid ${COLOR_PINK}`,
      background: 'transparent',
      fontWeight: 'bold',

      '&:hover': {
        background: COLOR_PINK,
        color: COLOR_WHITE,
      },
    },
  },

  understandable: {
    '& h2': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 24,
      '& b': {
        fontWeight: 700,
        color: COLOR_PURPLE,
      },
    },
    '& p': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },
    '& button': {
      fontFamily: 'Lato',
      transition: '.3s',
      color: COLOR_PURPLE,
      height: '52px',
      width: '100%',
      borderRadius: '1000px',
      border: `2px solid ${COLOR_PURPLE}`,
      background: 'transparent',
      fontWeight: 'bold',

      '&:hover': {
        background: COLOR_PURPLE,
        color: COLOR_WHITE,
      },
    },
  },
  memorable: {
    '& h2': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 24,
      '& b': {
        fontWeight: 700,
        color: COLOR_PURPLE,
      },
    },
    '& p': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 16,
      lineHeight: '22px',
      fontStyle: 'italic',
    },
    '& button': {
      fontFamily: 'Lato',
      transition: '.3s',
      color: COLOR_PURPLE,
      height: '52px',
      width: '100%',
      borderRadius: '1000px',
      border: `2px solid ${COLOR_PURPLE}`,
      background: 'transparent',
      fontWeight: 'bold',

      '&:hover': {
        background: COLOR_PURPLE,
        color: COLOR_WHITE,
      },
    },
  },
}));
