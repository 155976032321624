import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_PINK } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  whiteColor: {
    color: theme.palette.common.white,
  },
  templatesTitle: {
    fontFamily: 'Lato',
    fontSize: 24,
    lineHeight: '28px',
    color: COLOR_GRAY_70,
    '& b': {
      color: COLOR_PINK,
    },
    marginBottom: theme.spacing(4),
  },
  descriptionTemplates: {
    marginBottom: theme.spacing(3),
    color: COLOR_GRAY_70,
    fontSize: 16,
    lineHeight: '19px',
  },
  templatesContent: {
    '& button': {
      width: '100%',
      padding: theme.spacing(1.3, 3),
    },
  },
}));
