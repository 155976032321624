import { makeStyles } from '@mui/styles';
import { COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.grey[700],

    '& button': {
      width: '100%',
    },

    '& h1 span': {
      color: COLOR_PURPLE,
    },

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      marginTop: theme.spacing(4),
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      textAlign: 'left',

      '& img': {
        marginTop: 0,
      },
    },
  },
  subheadline: {
    color: theme.palette.grey[700],

    '& strong': {
      fontWeight: 700,
    },
  },
  textMonthsSeasonBreak: {
    fontSize: 23,
    color: '#D2C7C7',
  },
  textPriceSeasonBreak: {
    fontSize: 23,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
}));
