import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_20, COLOR_GRAY_70, COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2.5),
    borderTop: `1px solid ${COLOR_GRAY_20}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      marginTop: theme.spacing(4),
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      textAlign: 'left',

      '& img': {
        marginTop: 0,
      },
    },
  },
  videoWrapper: {
    zIndex: 1,
  },
  contentTitle: {
    '& b': {
      color: COLOR_GRAY_70,
      fontSize: 32,
      position: 'relative',
      zIndex: 2,
    },
    '& h1, & p': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
    },

    '& b span': {
      color: COLOR_PURPLE,
    },
  },
  ctaHero: {
    '& button': {
      width: '100%',
    },
    '& p': {
      fontSize: 14,
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
  },
  video: {
    width: '100%',
    marginTop: theme.spacing(4),
    borderRadius: theme.spacing(1.5),
    position: 'relative',
    zIndex: 2,

    [theme.breakpoints.up('md')]: {
      width: 650,
      marginTop: 0,
      zIndex: 2,
    },
  },
}));
