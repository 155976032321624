import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ width }) => Number(width),
    maxWidth: '100%',
  },
  wrapper: {
    position: 'relative',
    height: 0,
    paddingBottom: '56.25%', // 16:9 aspect ratio
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 9,
  },
}));
