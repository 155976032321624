import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import LazyLoad from 'react-lazyload';
import { useStyles } from './styles';
import NextLink from 'next/link';
import CustomButton from 'components/CustomButton';
import Image from 'next/image';

export default function MemorableSlidePresentations() {
  const classes = useStyles();

  return (
    <Box py={8} bgcolor="#F8F8F8" className={classes.infoContainer}>
      <LazyLoad offset={100}>
        <Container className={classes.templates}>
          <Box
            className="templates-txt"
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Typography variant="h2">
              Memorable <span>Slide Presentations</span>
            </Typography>

            <Box mt={4} mb={3} maxWidth={483}>
              <Typography className={classes.paragraph} component="p">
                <i>
                  “From day 1 the platform helped me to produce professional-looking slides for
                  presentations and figures for publications.”
                </i>
              </Typography>
            </Box>

            <Box mb={1} maxWidth={483}>
              <NextLink
                href={'https://www.researchgate.net/profile/Mohammed-Hankir'}
                target="_blank"
                passHref={true}
              >
                <Typography className={classes.linkReference} component="p">
                  Mohammed K Hankir
                </Typography>
              </NextLink>
            </Box>

            <Box mb={4} fontSize={14}>
              <Typography>
                University of Wuerzburg | JMU · Experimental Surgery
                <br />
                PhD Metabolism
              </Typography>
            </Box>

            <Box>
              <CustomButton variant="ctaPrimaryOutlineOpenModal">Sign up for free</CustomButton>
            </Box>
          </Box>

          <Box mr={9}>
            <Image
              src="/images/home-page/slide-presentations.webp"
              alt="slide presentations image"
              loading="lazy"
              width={478}
              height={337}
            />
          </Box>
        </Container>
      </LazyLoad>
    </Box>
  );
}
