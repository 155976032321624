import Image from 'next/image';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { Box } from '@mui/material';

const Props = {
  children: PropTypes.element.isRequired,
};

export default function WrapperBackgroundGraphics({ children }) {
  const classes = useStyles();

  return (
    <Box className={classes.root} bgcolor="#fff" position="relative">
      <Box className={classes.atomIcon}>
        <Image
          src="/images/background-graphics/atom.svg"
          alt="atom image"
          width={98}
          height={146}
          loading="eager"
        />
      </Box>

      <Box className={classes.chemistryIcon}>
        <Image
          src="/images/background-graphics/chemistry.svg"
          alt="chemistry image"
          width={117}
          height={115}
          loading="eager"
        />
      </Box>

      <Box className={classes.moonIcon}>
        <Image
          src="/images/background-graphics/moon.svg"
          alt="moon image"
          width={79}
          height={101}
          loading="eager"
        />
      </Box>

      <Box className={classes.moleculeIcon}>
        <Image
          src="/images/background-graphics/molecule.svg"
          alt="molecule image"
          width={111}
          height={133}
          loading="eager"
        />
      </Box>

      {children}
    </Box>
  );
}

WrapperBackgroundGraphics.propTypes = Props;
