import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import LazyLoad from 'react-lazyload';
import { useStyles } from './styles';
import CustomButton from 'components/CustomButton';
import Image from 'next/image';

export default function CustomizableGraphicalAbstracts() {
  const classes = useStyles();

  return (
    <Box pt={10} pb={7} bgcolor="common.white">
      <LazyLoad offset={100}>
        <Container className={classes.templates}>
          <Box
            className="templates-txt"
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Typography component="h2">
              Customizable{' '}
              <span>
                <b>Graphical Abstracts</b>
              </span>
            </Typography>

            <Box mt={4} mb={2} maxWidth={483}>
              <Typography className={classes.paragraph} component="p">
                <b>Visual representation for publication:</b> Is your research paper missing this?
              </Typography>
            </Box>

            <Box mt={2} mb={4} maxWidth={483}>
              <Typography className={classes.paragraph} component="p">
                <b>If yes,</b> Mind the Graph is the right place for you. Free users can publicly
                share with our watermark.
              </Typography>
            </Box>

            <Box>
              <CustomButton variant="ctaPinkOpenModal">Sign up for free</CustomButton>
            </Box>
          </Box>

          <Box ml={9}>
            <Image
              src="/images/home-page/graphical-abstracts.webp"
              alt="graphical abstracts"
              loading="lazy"
              width={478}
              height={337}
            />
          </Box>
        </Container>
      </LazyLoad>
    </Box>
  );
}
