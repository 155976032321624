import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import LazyLoad from 'react-lazyload';
import { useStyles } from './styles';
import CustomButton from 'components/CustomButton';
import Image from 'next/image';

export default function UnderstandableInfographics() {
  const classes = useStyles();

  return (
    <Box py={7} bgcolor="#F8F8F8" className={classes.infoContainer}>
      <LazyLoad offset={100}>
        <Container className={classes.templates}>
          <Box
            className="templates-txt"
            pt={4}
            pb={4}
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Typography component="h2">
              Easily Understandable <span>Infographics</span>
            </Typography>

            <Box mt={4} maxWidth={483}>
              <Typography className={classes.paragraph} component="p" variant="body1">
                Research indicates a <b>120% growth in citations</b> for articles using
                infographics.
              </Typography>
            </Box>

            <Box mt={3} maxWidth={483}>
              <Typography className={classes.paragraph} component="p" variant="body1">
                Why?
              </Typography>
            </Box>

            <Box maxWidth={483} mt={3} mb={3}>
              <Typography className={classes.paragraph} component="p" variant="body2">
                Because they are a great tool for distilling complex data and content into
                digestible, engaging visuals.
              </Typography>
            </Box>

            <Box>
              <CustomButton variant="ctaPrimaryOutlineOpenModal">Sign up for free</CustomButton>
            </Box>
          </Box>

          <Box mr={9}>
            <Image
              src="/images/home-page/infographics.webp"
              alt="infographics image"
              loading="lazy"
              width={478}
              height={337}
            />
          </Box>
        </Container>
      </LazyLoad>
    </Box>
  );
}
