import React from 'react';
import Image from 'next/image';
import { Container, Typography, Box } from '@mui/material';
import { useRouter } from 'next/router';
import LazyLoad from 'react-lazyload';
import { useStyles } from './styles';
import CustomButton from 'components/CustomButton';

export default function ImpactfulPostersMobile() {
  const classes = useStyles();
  const router = useRouter();

  function handleButtonClick() {
    router.push(`/auth/login-register?from_url=${router.basePath + router.asPath}`);
  }

  return (
    <Box px={1} py={7} bgcolor="common.white">
      <Container className={classes.templates}>
        <LazyLoad offset={100}>
          <Box
            className="templates-txt"
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Typography variant="h2">
              Impactful{' '}
              <span>
                <b>Posters</b>
              </span>
            </Typography>

            <Box mt={4} mb={2} maxWidth={483}>
              <Typography className={classes.paragraph} component="p">
                <b>Stand out at any event</b> or environment with visually stunning posters.
              </Typography>
            </Box>

            <Box mt={2} mb={3} maxWidth={483}>
              <Typography className={classes.paragraph} component="p">
                <b>Create high-quality</b> scientific graphics that capture attention and
                effectively convey your messages.
              </Typography>
            </Box>
          </Box>

          <Box>
            <Image
              src="/images/home-page/posters.webp"
              width={478}
              height={337}
              alt="posters image"
              loading="lazy"
            />
          </Box>

          <Box>
            <CustomButton onClick={handleButtonClick} variant="ctaPink">
              Sign up for free
            </CustomButton>
          </Box>
        </LazyLoad>
      </Container>
    </Box>
  );
}
