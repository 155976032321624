import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import EmbedVideo from 'components/EmbedVideo';
import { useStyles } from './styles';
import LazyLoad from 'react-lazyload';

export default function UnleashInfographics() {
  const classes = useStyles();

  return (
    <Box py={6} bgcolor="#5F29CC">
      <Container>
        <LazyLoad offset={100}>
          <Box mb={6}>
            <Typography className={classes.whiteColor} component="h2" variant="h4" align="center">
              <b>Unleash the Power of Infographics with Mind the Graph</b>
            </Typography>
          </Box>

          <Grid container={true} spacing={2} alignItems="center">
            <Grid item={true} xs={12} md={6}>
              <LazyLoad offset={100}>
                <EmbedVideo
                  classes={{ root: classes.video }}
                  url="https://www.youtube.com/embed/Y2YMnuQPTFA?si=MsAK5LLvazozyqwo"
                  width="500"
                />
              </LazyLoad>
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <Typography className={classes.whiteColor}>
                Join our fast-growing community of users as <br />
                we revolutionize scientific communication!
              </Typography>

              <div className={classes.infoBox}>
                <div className={classes.infoBoxItem}>
                  <img
                    src="/images/120.svg"
                    alt="120% icon"
                    loading="lazy"
                    width="107"
                    height="37"
                  />

                  <Typography>Growth in citations for articles with infographics</Typography>
                </div>

                <div className={classes.infoBoxItem}>
                  <img
                    src="/images/high-impact-icon.svg"
                    alt="High impact icon"
                    loading="lazy"
                    width="85"
                    height="60"
                  />

                  <Typography>High impact and greater visibility for your work</Typography>
                </div>

                <div className={classes.infoBoxItem}>
                  <img
                    src="/images/lab-icon.svg"
                    alt="Lab icon"
                    loading="lazy"
                    width="47"
                    height="60"
                  />

                  <Typography>
                    Exclusive scientific content, created by scientists, for the needs of the
                    community
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </LazyLoad>
      </Container>
    </Box>
  );
}
