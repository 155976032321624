import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import EmbedVideo from 'components/EmbedVideo';
import { useStyles } from './styles';
import LazyLoad from 'react-lazyload';
import { COLOR_PURPLE_HOVER } from 'styles/variables/colors';

export default function UnleashInfographicsMobile() {
  const classes = useStyles();

  return (
    <Box py={3} bgcolor={COLOR_PURPLE_HOVER}>
      <Container>
        <LazyLoad offset={100}>
          <Box mb={2}>
            <Typography className={classes.whiteColor} component="h2" variant="h6" align="center">
              <b>Unleash the Power of Infographics with Mind the Graph</b>
            </Typography>

            <Box pt={2}>
              <Typography className={classes.whiteColor} align="center">
                Join our fast-growing community of users as <br />
                we revolutionize scientific communication!
              </Typography>
            </Box>
          </Box>

          <Grid container={true} spacing={2} alignItems="center">
            <Grid item={true} xs={12}>
              <LazyLoad offset={100}>
                <EmbedVideo
                  classes={{ root: classes.video }}
                  url="https://www.youtube.com/embed/tG-PmLzx6NA"
                  width="500"
                />
              </LazyLoad>
            </Grid>

            <Grid item={true} xs={12}>
              <div className={classes.infoBox}>
                <div className={classes.infoBoxItem}>
                  <img
                    src="/images/120.svg"
                    alt="120% icon"
                    loading="lazy"
                    width="101"
                    height="56"
                  />

                  <Typography component="p" align="center">
                    Growth in citations for articles with infographics
                  </Typography>
                </div>

                <div className={classes.infoBoxItem}>
                  <img
                    src="/images/high-impact-icon.svg"
                    alt="High impact icon"
                    loading="lazy"
                    width="76"
                    height="53"
                  />

                  <Typography component="p" align="center">
                    High impact and greater visibility for your work
                  </Typography>
                </div>

                <div className={classes.infoBoxItem}>
                  <img
                    src="/images/lab-icon.svg"
                    alt="Lab icon"
                    loading="lazy"
                    width="42"
                    height="54"
                  />

                  <Typography component="p" align="center">
                    Exclusive scientific content, created by scientists, for the needs of the
                    community
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </LazyLoad>
      </Container>
    </Box>
  );
}
