import { Box, Container, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useStyles } from './styles';
import { COLOR_WHITE } from 'styles/variables/colors';
import CustomButton from 'components/CustomButton';
import LazyLoad from 'react-lazyload';
import { useAuthUser } from 'features/auth/AuthUserContext';

export default function PreMadeTemplatesMobile() {
  const classes = useStyles();
  const router = useRouter();
  const { isAuthenticated } = useAuthUser();

  function handleButtonClick() {
    if (!isAuthenticated) {
      router.push(`/auth/login-register?from_url=${router.basePath + router.asPath}`);
    } else {
      router.push('/unsupported-device');
    }
  }

  return (
    <Box px={1} className={classes.templatesContent} pt={5} pb={7} bgcolor={COLOR_WHITE}>
      <Container>
        <LazyLoad offset={100}>
          <Typography className={classes.templatesTitle} component="h2" variant="h6">
            <b>Templates.</b>
            <br /> Save time on your research with them.
          </Typography>

          <Typography className={classes.descriptionTemplates}>
            <b>Our 300+ pre-made</b> templates, built by science designers, for Medical, Biology,
            Molecular and many other fields.
          </Typography>

          <CustomButton variant="ctaPink" onClick={handleButtonClick}>
            Sign up for free
          </CustomButton>

          <Box pt={5} alignItems="center" display="flex" justifyContent="center">
            <Image
              src="/images/home-page-mobile/algorithmsNotesForProfessionals.webp"
              alt="infographic image"
              width={327}
              height={291}
            />
          </Box>
        </LazyLoad>
      </Container>
    </Box>
  );
}
