import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles(() => ({
  mainContainer: {
    background: '#EFEDF6',
    color: COLOR_GRAY_70,
  },
  textWhite: {
    fontWeight: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 30,

    '& span': {
      color: COLOR_PURPLE,
      fontWeight: 800,
      fontSize: 30,
    },
  },
  paragraph: {
    fontSize: 18,

    '& span': {
      color: COLOR_GRAY_70,
      fontWeight: 700,
    },
  },
  skyRocketing: {
    fontFamily: 'Lato',
    color: COLOR_GRAY_70,
  },
}));
