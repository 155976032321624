import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import LazyLoad from 'react-lazyload';
import { useStyles } from './styles';
import CustomButton from 'components/CustomButton';
import Image from 'next/image';

export default function PreMadeTemplates() {
  const classes = useStyles();

  return (
    <Box pt={5} pb={10} bgcolor="common.white">
      <LazyLoad offset={100}>
        <Container className={classes.templates}>
          <div className="templates-txt">
            <h3>
              <span>Templates.</span>
              <br />
              Save time on your research with them.
            </h3>

            <Box mt={4} mb={4} maxWidth={530}>
              <Typography className={classes.paragraph} component="p" variant="body1">
                <b>Our 300+ pre-made</b> templates, built by science designers, for Medical,
                Biology, Molecular and many other fields.
              </Typography>
            </Box>

            <Box>
              <CustomButton variant="ctaPinkOpenModal">Sign up for free</CustomButton>
            </Box>
          </div>

          <Box ml={2} className="templates-img">
            <Image
              src="/images/home-page/infographic.webp"
              alt="infographic image"
              width={478}
              height={425}
            />
          </Box>
        </Container>
      </LazyLoad>
    </Box>
  );
}
