import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  primaryColor: {
    color: '#7833FF',
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  video: {
    margin: '0 auto',
  },
  infoBox: {
    display: 'flex',
    marginTop: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: '#7833FF',
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
  infoBoxItem: {
    flexBasis: '33.33%',
    padding: theme.spacing(2, 0.5),
    color: theme.palette.common.white,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'flex-start',

    '& p': {
      fontSize: 15,
    },

    '& img': {
      height: 60,
      marginBottom: theme.spacing(1),
    },
  },
}));
