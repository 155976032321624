import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  templates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& h3': {
      fontSize: 30,
      fontWeight: 400,
      color: '#616161',
    },

    '& p': {
      color: '#616161',
    },

    '& h3 span': {
      color: '#D6246E',
      fontWeight: 700,
    },

    '& img': {
      marginLeft: '72px',
    },
  },
  paragraph: {
    lineHeight: 1.15,
    fontFamily: 'Lato',
  },
}));
