import React from 'react';
import { useStyles } from './styles';
import { Container, Box, Typography } from '@mui/material';
import LazyLoad from 'react-lazyload';
import { useRouter } from 'next/router';
import TrustedResearchersMobile from 'components/TrustedResearchersMobile';
import AccurateIllustrationsGalleryMobile from 'components/AccurateIllustrationsGalleryMobile';
import InfodesignMobile from 'components/InfodesignMobile';
import UnleashInfographicsMobile from 'components/UnleashInfographicsMobile';
import TestimonialsMobile from 'components/TestimonialsMobile';
import CustomButton from 'components/CustomButton';
import PreMadeTemplatesMobile from 'components/PreMadeTemplatesMobile';
import ScientificDesignToolMobile from 'components/ScientificDesignToolMobile';
import CustomizableGraphicalAbstractsMobile from 'components/CustomizableGraphicalAbstractsMobile';
import UnderstandableInfographicsMobile from 'components/UnderstandableInfographicsMobile';
import ImpactfulPostersMobile from 'components/ImpactfulPostersMobile';
import MemorableSlidePresentationsMobile from 'components/MemorableSlidePresentationsMobile';

export default function MainContentMobileB() {
  const classes = useStyles();
  const router = useRouter();

  function handleButtonClick() {
    router.push(`/auth/login-register?from_url=${router.basePath + router.asPath}`);
  }

  return (
    <>
      <Box mt={3}>
        <TrustedResearchersMobile title="Trusted by researchers and academics from 100+ institutions" />
      </Box>

      <PreMadeTemplatesMobile />

      <ScientificDesignToolMobile />

      <CustomizableGraphicalAbstractsMobile />

      <UnderstandableInfographicsMobile />

      <ImpactfulPostersMobile />

      <MemorableSlidePresentationsMobile />

      <AccurateIllustrationsGalleryMobile boxBgColor="#F1F1F1" />

      <InfodesignMobile variantTitle="h6" showButton={true} />

      <UnleashInfographicsMobile />

      <Box py={10} bgcolor="#FFFFFF">
        <Container>
          <TestimonialsMobile showActionButton={false} />
        </Container>
      </Box>

      <LazyLoad offset={100}>
        <Box py={5} mt={-6} bgcolor="#5F29CC">
          <Container>
            <Typography className={classes.whiteColor} align="center" component="h2" variant="h6">
              <b>Ready to join Mind The Graph?</b>
            </Typography>

            <Box py={2}>
              <Typography className={classes.whiteColor} align="center">
                Sign up for free and make scientifically <br /> accurate infographics in minutes
              </Typography>
            </Box>

            <Box pt={2} align="center">
              <CustomButton onClick={handleButtonClick}>
                <b>SIGN UP FOR FREE</b>
              </CustomButton>
            </Box>
          </Container>
        </Box>
      </LazyLoad>
    </>
  );
}
