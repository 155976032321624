import Image from 'next/image';
import { Box, Container, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useStyles } from './styles';
import { COLOR_WHITE } from 'styles/variables/colors';
import CustomButton from 'components/CustomButton';
import LazyLoad from 'react-lazyload';

export default function CustomizableGraphicalAbstractsMobile() {
  const classes = useStyles();
  const router = useRouter();

  function handleButtonClick() {
    router.push('/unsupported-device');
  }

  return (
    <Box px={1} py={7} className={classes.graphicalAbstracts} bgcolor={COLOR_WHITE}>
      <Container>
        <LazyLoad offset={100}>
          <Typography className={classes.graphicalAbstractsTitle} component="h2" variant="h6">
            Customizable
            <br />
            <b>Graphical Abstracts</b>
          </Typography>

          <Box my={3}>
            <Typography>
              <b>Visual representation for publication:</b> Is your research paper missing this?
            </Typography>

            <Typography>
              <b>If yes,</b> Mind the Graph is the right place for you. Free users can publicly
              share with our watermark.
            </Typography>
          </Box>

          <Box py={5} alignItems="center" display="flex" justifyContent="center">
            <Image
              src="/images/home-page-mobile/graphic-abstracts.webp"
              width={273}
              height={83}
              alt="infographic image"
              loading="lazy"
            />
          </Box>

          <CustomButton variant="ctaPink" onClick={handleButtonClick}>
            Sign up for free
          </CustomButton>
        </LazyLoad>
      </Container>
    </Box>
  );
}
