import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_PINK, COLOR_PURPLE, COLOR_WHITE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  onlineScientific: {
    '& h2': {
      fontFamily: 'Lato',
      lineHeight: '28.8px',
      fontSize: 24,
      color: COLOR_GRAY_70,
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      '& b': {
        color: COLOR_PURPLE,
      },
    },
    '& p': {
      color: COLOR_GRAY_70,
      fontSize: 16,
      lineHeight: '22px',
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      fontFamily: 'Lato',
    },
    '& .description': {
      marginBottom: 0,
    },

    '& button': {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1.3, 3),
    },
    '& span b': {
      display: 'block',
      fontSize: 14,
      textAlign: 'center',
    },
  },
}));
