import Image from 'next/image';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useStyles } from './styles';

const Props = {
  children: PropTypes.element.isRequired,
};

export default function WrapperBackgroundGraphicsMobile({ children }) {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.imageMolecule}>
        <Image
          src="/images/background-graphics/molecule.svg"
          width={75}
          height={91}
          alt="molecule image"
          loading="eager"
        />
      </Box>

      <Box className={classes.cell}>
        <Image
          src="/images/background-graphics/chemistry.svg"
          width={65}
          height={65}
          alt="cell image"
          loading="eager"
        />
      </Box>

      {children}
    </div>
  );
}

WrapperBackgroundGraphicsMobile.propTypes = Props;
