import { makeStyles } from '@mui/styles';
import { COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  carouselRoot: {
    padding: 0,
  },
  primaryColor: {
    color: COLOR_PURPLE,
  },
  carousel: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 3, 0, 0),
    },
  },
  carouselItem: {
    textAlign: 'left !important',
    padding: theme.spacing(0, 1, 0, 3.6),
  },
  item: {
    position: 'relative',
    padding: theme.spacing(3, 1, 3, 5),
    borderRadius: 15,
    border: '1px solid rgba(0, 0, 0, .15)',
    backgroundColor: '#F6F2F2',
  },
  avatarLink: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: -30,
    marginTop: -30,
    width: 60,
    height: 60,

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  ratingIcon: {
    maxWidth: 140,
    marginTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(1.5, 3),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 40,
    backgroundColor: '#7833FF',

    '&:hover': {
      backgroundColor: '#5F29CC',
    },
  },
  testimonialName: {
    fontSize: 13,
    fontWeight: 'bold',
    marginTop: 13,
    marginBottom: theme.spacing(0.5),
  },
  testimonialInfo: {
    fontSize: 9,
  },
}));
