import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_PURPLE } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  understandable: {
    '& h2': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 24,
      '& b': {
        fontWeight: 700,
        color: COLOR_PURPLE,
      },
    },
    '& p': {
      color: COLOR_GRAY_70,
      fontFamily: 'Lato',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },
    '& button': {
      width: '100%',
    },
  },
}));
