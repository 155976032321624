import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import LazyLoad from 'react-lazyload';
import CustomButton from 'components/CustomButton';
import Image from 'next/image';
import { useStyles } from './styles';

export default function ScientificDesignTool() {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer} py={7}>
      <Container>
        <LazyLoad offset={100}>
          <Box maxWidth={580} ml="auto" mr="auto">
            <Typography className={classes.textWhite} align="center" variant="h5" component="h2">
              <span className={classes.paragraph}>Online scientific design tool</span> for your
              classes, speeches, papers and much more!
            </Typography>
          </Box>

          <Box mt={4} maxWidth={640} ml="auto" mr="auto">
            <Typography className={classes.paragraph} variant="body2" align="center">
              <span>Craft your own personalized narratives,</span> with a suite of editing tools
              including color alteration, cropping, text addition, and more!
            </Typography>
          </Box>

          <Box pt={4} pb={4} px={10} textAlign="center">
            <CustomButton variant="ctaPrimaryOpenModal">Sign up for free</CustomButton>
          </Box>

          <Box display="flex" justifyContent="center" fontSize={14}>
            <Image
              src="/images/home-page/people.webp"
              alt="people image"
              loading="lazy"
              width={83}
              height={24}
            />
            <Box ml={1} fontSize={14} display="flex" alignItems="center">
              <Typography className={classes.skyRocketing} variant="body2">
                500,000+ users are skyrocketing their academic careers with us!
              </Typography>
            </Box>
          </Box>
        </LazyLoad>
      </Container>
    </Box>
  );
}
