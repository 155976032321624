import { makeStyles } from '@mui/styles';
import { COLOR_GRAY_70, COLOR_PINK } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  mainTitle: {
    color: COLOR_GRAY_70,
    fontSize: 22,

    '& span': {
      color: COLOR_PINK,
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '10px',
  },
}));
