import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useStyles } from './styles';
import LazyLoad from 'react-lazyload';
import { ChevronRight } from '@mui/icons-material';
import CustomButton from 'components/CustomButton';
import { useRouter } from 'next/router';

export default function FriendsReferralBanner() {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box bgcolor="#FFFFFF" py={8}>
      <Container>
        <LazyLoad offset={100}>
          <Box display="flex" justifyContent="center">
            <Box mr={4}>
              <img
                src="/images/gift-icon-primary.svg"
                alt="gift icon"
                loading="lazy"
                width="99"
                height="93"
              />
            </Box>

            <Typography component="h2" variant="h4" align="left" className={classes.primaryColor}>
              <b>
                Invite your friends to Mind the Graph's <br /> Infographic Maker. Get rewards!
              </b>
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="start" mt={5}>
            <Box display="flex" alignItems="start">
              <Box className={classes.stepNumber}>
                <b>01</b>
              </Box>

              <Box ml={2} mr={4}>
                <Typography className={classes.primaryColor}>
                  <b>
                    Login or create a <br /> brand new account <br /> at Mind the Graph.
                  </b>
                </Typography>
              </Box>
            </Box>

            <ChevronRight className={classes.chevron} />

            <Box display="flex" alignItems="start">
              <Box className={classes.stepNumber}>
                <b>02</b>
              </Box>

              <Box ml={2} mr={4}>
                <Typography className={classes.primaryColor}>
                  <b>
                    Send a free trial <br /> invitation. The more <br /> friends you connect <br />{' '}
                    the more rewards <br /> you'll get.
                  </b>
                </Typography>
              </Box>
            </Box>

            <ChevronRight className={classes.chevron} />

            <Box display="flex" alignItems="start">
              <Box className={classes.stepNumber}>
                <b>03</b>
              </Box>

              <Box ml={2} mr={4}>
                <Typography className={classes.primaryColor}>
                  <b>
                    Enjoy your rewards, <br /> including up to a <br /> 100% discount or 7 <br />{' '}
                    days experiencing all <br /> benefits of a <br />
                    Researcher <br />
                    Subscription.
                  </b>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box pt={3} textAlign="center">
            <CustomButton onClick={() => router.push('/friends-referral')}>
              Invite your friends now
            </CustomButton>
          </Box>
        </LazyLoad>
      </Container>
    </Box>
  );
}
