import BaseLayout from 'layouts/BaseLayout';
import MainContent from 'components/Home/MainContent';
import MainContentMobile from 'components/Home/MainContentMobile';
import Header from 'components/Home/Header';
import HeaderMobile from 'components/Home/HeaderMobile';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import useIsMobile from 'hooks/useIsMobile';
import { COLOR_WHITE } from 'styles/variables/colors';

const schemaData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://mindthegraph.com/',
  name: 'Best Free Infographic Maker for Science | Mind the Graph',
  description:
    'Mind the Graph its a free infographic maker online for medical, doctors and scientists. Find scientific illustrations, infographics and templates. Access here!',
  logo: 'https://mindthegraph.com/assets/img/header/logo.svg',
  sameAs: [
    'https://facebook.com/mindthegraph',
    'https://instagram.com/mindthegraph',
    'https://linkedin.com/company/mind-the-graph/',
    'https://mindthegraph.com/blog/',
    'https://twitter.com/mindthegraph',
    'https://www.youtube.com/c/MindtheGraph',
  ],
};

const schemaDataFaq = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    {
      '@type': 'Question',
      name: 'Can I publish infographics I create with Mind the Graph? How do I cite them?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'All content stored in and created with mindthegraph.com is available under a free culture Creative Commons license. Free users can publicly present infographics created with the Mind the Graph platform and use the illustrations available on the website. However, Mind The Graph must be cited, and the use of the watermark is mandatory. Paid subscribers are recognized as authors and are entitled to full rights to their creations, with the rights and ability to transfer authorial rights to third-party publishers without previous consulting Mind The Graph. So, if your a paid user, you dont need to attribute credit to Mind the Graph (but well be happy if you do!).',
      },
    },
    {
      '@type': 'Question',
      name: 'Can I use Mind the Graph for free?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes, Mind the Graph is based on a freemium model and you can use it for free for as long as you wish. We dont request any credit card information upon registration. However, keep in mind that the Free plan is for you to get to know the platform and informal communication with your peers or educational environments. As a free user, you must retain Mind the Graphs watermark in all cases and cite the platform in formal communication, publications, lectures, and presentations. If you ever want to upgrade, all paid plans provide access to more figures, watermark-free images, and Creative Commons licenses for your creations.',
      },
    },
    {
      '@type': 'Question',
      name: 'Do I need to pay to request an illustration?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Mind the Graph is the worlds largest scientifically accurate illustrations gallery. There are chances that we have the illustration you are looking for. However, if we still dont have an illustration that meets your needs, you can subscribe and request it for free. Check our tutorial for help.',
      },
    },
    {
      '@type': 'Question',
      name: 'What are the benefits of a paid plan?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'A Paid plan at Mind the Graph gets you extra features and resources! All paid plans offer a wider range of illustrations, watermark-free use of images, and Creative Commons licenses for your creations. Also, youll have access to premium customer support, free personalized illustrations, numerous templates, and higher limits on the use of scientific illustrations. Dont forget to compare plans here.',
      },
    },
  ],
};

export default function Home() {
  const [scrolled, setScrolled] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Head>
        <title>Best Free Infographic Maker for Science | Mind the Graph</title>

        <meta
          key="description"
          name="description"
          property="description"
          content="Visually appealing figures for your research. Browse through 40,000+ scientifically accurate illustrations in 80+ popular fields."
        />
        <meta
          name="keywords"
          content="infographic generator, infographic maker, free infographic maker, online infographic maker, create infographics, create infographics online, infographic creator, make an infographic, infographic software, data visualization tools"
        />
        <meta
          key="og:title"
          name="og:title"
          property="og:title"
          content="Best Free Infographic Maker for Science | Mind the Graph"
        />
        <meta
          name="google-site-verification"
          content="OHfP0Y2tRtWKAEGJgheqcDCt1u2EpgfvQDiA4ZAsgWM"
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaDataFaq),
          }}
        />
      </Head>
      <BaseLayout
        scrolled={scrolled}
        bgBrandColor={COLOR_WHITE}
        typeBtn="buttonSecondaryOpenModal"
        hero={!isMobile ? <Header /> : <HeaderMobile />}
        hasPadding={false}
        hamburgerMenuActive={true}
        headerWhite={true}
      >
        {!isMobile ? <MainContent /> : <MainContentMobile />}
      </BaseLayout>
    </>
  );
}
