import { makeStyles } from '@mui/styles';
import { COLOR_PINK } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  display: 'flex',
  templates: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: 'Lato',
    '& h2': {
      fontSize: 24,
      fontWeight: 400,
      color: '#616161',
      margin: 0,
      fontFamily: 'Lato',
    },

    '& p': {
      color: '#616161',
    },

    '& h2 span b': {
      color: COLOR_PINK,
      fontWeight: 700,
    },

    '& button': {
      width: '100%',
      marginTop: theme.spacing(3),
    },
  },
  paragraph: {
    lineHeight: 1.15,
    fontFamily: 'Lato',
  },
}));
