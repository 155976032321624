import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  imageMolecule: {
    position: 'absolute',
    right: '-5px',
    top: '330px',
    zIndex: 1,
  },
  cell: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    left: 0,
  },
}));
