import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2.5),
  },
  atomIcon: {
    position: 'absolute',
    left: '-20px',
    bottom: '25%',
    zIndex: '0',
    [theme.breakpoints.down(1300)]: {
      display: 'none',
    },
  },
  chemistryIcon: {
    position: 'absolute',
    left: '41%',
    top: '0',
    zIndex: '0',
    [theme.breakpoints.down(1212)]: {
      display: 'none',
    },
  },
  moonIcon: {
    position: 'absolute',
    right: '0',
    bottom: '30px',
    zIndex: '0',
    [theme.breakpoints.down(1212)]: {
      display: 'none',
    },
  },
  moleculeIcon: {
    position: 'absolute',
    right: '17%',
    top: '27px',
    zIndex: '0',
  },
}));
