import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import LazyLoad from 'react-lazyload';
import CustomButton from 'components/CustomButton';
import { makeStyles } from '@mui/styles';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useAuthUser } from 'features/auth/AuthUserContext';

const Props = {
  ctaButton: PropTypes.bool,
};

const DefaultProps = {
  ctaButton: false,
};

const useStyles = makeStyles(() => ({
  primaryColor: {
    color: '#7833FF',
  },
  textInfo: {
    color: '#757575',

    '& a': {
      textDecoration: 'underline',
    },
  },
  videoWrapper: {
    zIndex: 1,
  },
}));

export default function AccurateIllustrationsGallery({ ctaButton }) {
  const classes = useStyles();
  const { register } = useAuthUser();

  return (
    <Box pt={4}>
      <Container>
        <LazyLoad offset={100}>
          <Typography component="h2" variant="h5" align="center" className={classes.primaryColor}>
            <b>The World's Largest Scientifically-Accurate Illustrations Gallery</b>
          </Typography>

          <Box py={2}>
            <Typography component="p" variant="body2" className={classes.textInfo} align="center">
              Over 75,000 accurate scientific figures to boost your impact. As scientists <br />
              ourselves, we know the difference between a rat and a mouse!
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <video
              src="/videos/accurate-illustrations-gallery/versions-new-workspace-video.webm"
              alt="versions video"
              loading="lazy"
              muted={true}
              loop={true}
              autoPlay={true}
              width={290}
            />

            <Box ml={8}>
              <Image
                src="/images/illustrations-banner.webp"
                alt="illustrations banner image"
                loading="lazy"
                width={517}
                height={250}
              />
            </Box>
          </Box>

          <Box py={5} textAlign="center">
            {!ctaButton ? (
              <CustomButton onClick={register}>Start creating now</CustomButton>
            ) : (
              <CustomButton variant="ctaPrimaryOpenModal">Start creating now</CustomButton>
            )}
          </Box>

          <Divider />
        </LazyLoad>
      </Container>
    </Box>
  );
}

AccurateIllustrationsGallery.propTypes = Props;
AccurateIllustrationsGallery.defaultProps = DefaultProps;
