import { Box, Container, Typography } from '@mui/material';
import Image from 'next/image';
import { useStyles } from './styles';
import CustomButton from 'components/CustomButton';
import { useRouter } from 'next/router';
import LazyLoad from 'react-lazyload';

export default function MemorableSlidePresentationsMobile() {
  const classes = useStyles();
  const router = useRouter();

  function handleButtonClick() {
    router.push(`/auth/login-register?from_url=${router.basePath + router.asPath}`);
  }
  return (
    <Box px={1} py={7} className={classes.memorable} bgcolor="#F8F8F8">
      <Container>
        <LazyLoad offset={100}>
          <Box alignItems="center" display="flex" justifyContent="center" mb={6}>
            <Image
              src="/images/home-page-mobile/memorable.webp"
              width={273}
              height={120}
              alt="Memorable Slide Presentations"
            />
          </Box>

          <Typography component="h2" variant="h6">
            Memorable <br />
            <b>Slide Presentations</b>
          </Typography>

          <Box my={3}>
            <Typography>
              “From day 1 the platform helped me to produce professional-looking slides for
              presentations and figures for publications.”
            </Typography>
          </Box>

          <Box className={classes.boxMohammed} pb={3}>
            <a href="#">Mohammed K Hankir</a>
            <Typography>
              University of Wuerzburg | JMU · Experimental Surgery PhD Metabolism
            </Typography>
          </Box>

          <CustomButton variant="ctaPrimaryOutline" onClick={handleButtonClick}>
            Sign up for free
          </CustomButton>
        </LazyLoad>
      </Container>
    </Box>
  );
}
